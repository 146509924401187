@font-face {
  font-family: Suisse-Regular;
  src: url("./assets/fonts/SuisseIntl-Regular-WebS.woff2") format("woff2"), url("./assets/fonts/SuisseIntl-Regular-WebS.woff") format("woff"), url("./assets/fonts/SuisseIntl-Regular-WebS.ttf") format("truetype")
}
@font-face {
  font-family: Suisse-Medium;
  src: url("./assets/fonts/SuisseIntl-Medium-WebS.woff2") format("woff2"), url("./assets/fonts/SuisseIntl-Medium-WebS.woff") format("woff"), url("./assets/fonts/SuisseIntl-Medium-WebS.ttf") format("truetype")
}

html, body, #root, .App {
  font-family: Suisse-Regular, sans-serif;
  font-weight: 400;
  height: 100%;
  width: 100%;
  margin: 0;
  color: #222;
}

a {
  text-decoration: none;
  color: #222;
}

.nav-button {
  position: absolute;
  height: 50px;
  width: 50px;
  border: none;
  background-color: #222;
  z-index: 900;
  cursor: pointer;

  span {
    position: absolute;
    color: #fff;
    font-size: 30px;
    padding: 6px 4px;
  }

  &:focus { outline: 0; }

  &.back-home {
    top: 0;
    right: 0;
  }
}

header {
  font-family: Suisse-Medium, sans-serif;
  text-transform: uppercase;
  z-index: 1000;
  padding: 0.8rem 1rem;
  font-size: 2.8em;
  max-width: 70vw;
  line-height: 1;
  user-select: none;
}

.carousel-wrapper {
  position: relative;
  box-sizing: border-box;
  
  .carousel-nav {
    display: none;
  }
  
  .carousel {
    margin: 0;
    padding: 0;
    list-style: none;

    .slide {
      display: block;

      img {
        max-width: 100%;
        height: auto;
        position: static;
      }

      .exhibit-info {
        font-size: 0.9em;
        position: relative;
        color: #5e5e5e;
        margin: 1rem 1rem 2rem;
        user-select: none;

        .artist-name {
          font-size: 1.2em;
          margin: 0;
          text-transform: uppercase;
        }
        .exhibit-name {
          font-size: 0.9em;
          margin: 0;
          padding-top: 0.5rem;
        }
      }
    }
  }
}

.display-wrapper {
  height: 100%;

  .display-main {
    display: none;
  }

  .display-collection {
    padding: 0;
    list-style: none;
    margin: 0;

    .display-item {
      display: block;

      img {
        max-width: 100%;
        height: auto;
        position: static;
      }
    }
  }
}

@media only screen and (min-width: 768px) {
  header {
    position: fixed;
    padding: 3rem;
    max-width: none;
  }

  .back-button {
    span { left: 0; }
  }
  
  .carousel-wrapper {
    position: absolute;
    height: 100%;
    width: 100%;
    overflow-x: hidden;
    padding-left: 2rem;
    display: flex;
    flex-direction: column;
    justify-content: center;
    
    .carousel-nav {
      display: block;

      .nav-button {
        top: 50%;
        transform: translateY(-50%);
        padding: 0;

        span { position: relative; }

        &.prev-slide {
          margin-left: 1rem;
          span {
            float: left;
          }
        }
        &.next-slide {
          margin-right: 3rem;
          right: 0;
          span {
            float: right;
          }
        }
      }
    }
      
    .carousel {
      position: relative;
      height: 100%;
      width: fit-content;
      margin: 0;
      padding: 0;
      display: flex;
      flex-direction: row;
      flex-wrap: nowrap;
      list-style: none;
      transition-duration: 300ms;
      transition-timing-function: ease-in-out;
      will-change: transform;
      
      .slide {
        padding-left: 1rem;
        display: inline-block;

        img {
          max-width: none;
          height: 500px;
          background-size: cover;
        }

        .exhibit-info {
          font-size: 1em;
          position: absolute;
          margin: 0;

          .artist-name {
            padding-top: 0.5rem;
          }
        }
      }
    }
  }
  
  .display-wrapper {
    position: absolute;
    height: 100%;
    width: 100%;
    box-sizing: border-box;
    overflow-x: hidden;
    
    .display-container {
      height: 100%;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      padding: 2rem 0;
      box-sizing: border-box;
      
      .display-main {
        display: block; 
        height: 70vh;
        
        img {
          max-height: 100%;
          background-size: contain;
          background-position: center;
        }
      }

      .display-collection {
        position: relative;
        height: 150px;
        width: fit-content;
        margin: 2rem auto 0;
        padding: 0;
        white-space: nowrap;
        list-style: none;
  
        .display-item {
          display: inline-block;
  
          img {
            opacity: 0.3;
            transition-property: opacity;
            transition-duration: 200ms;
            transition-timing-function: ease-in;
            height: 150px;
            padding: 0 4px;
  
            &:hover {
              opacity: 0.7;
            }
          }
        }
      }
    }
  }
}

